var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInformations,
            expression: "showInformations",
          },
        ],
        staticClass: "wrapper",
      },
      [
        _c(
          "div",
          { staticClass: "wrapper__card" },
          [
            _c("div", { staticClass: "wrapper__card__header" }, [
              _c("div", { staticClass: "left" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      "Configure o template selecionado".toLocaleUpperCase()
                    )
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("z-btn", {
                    attrs: {
                      text: "Salvar Template",
                      rounded: "",
                      primary: "",
                      "is-loading": _vm.loadingTempalte,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.saveTemplate()
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "wrapper__card__body mb-5" }, [
              _c(
                "div",
                { staticClass: "wrapper__card__body__left" },
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5", sm: "5" } },
                            [
                              _c("z-input", {
                                attrs: {
                                  rules: [(v) => !!v || "Campo obrigatório"],
                                  hideDetails: true,
                                  placeholder: "Nome do template",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.templateToSave.templateName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.templateToSave,
                                      "templateName",
                                      $$v
                                    )
                                  },
                                  expression: "templateToSave.templateName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5", sm: "5" } },
                            [
                              _c("z-autocomplete", {
                                attrs: {
                                  label: "Selecione uma conexão",
                                  items: _vm.dbConfigList,
                                  itemText: "name",
                                  placeholder: "Pesquisar",
                                  type: "text",
                                  hideDetails: true,
                                  returnObject: "",
                                  rules: [(v) => !!v || "Campo obrigatório"],
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.chooseCollection()
                                  },
                                },
                                model: {
                                  value: _vm.choosenConnection,
                                  callback: function ($$v) {
                                    _vm.choosenConnection = $$v
                                  },
                                  expression: "choosenConnection",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("z-input", {
                                attrs: {
                                  hideDetails: true,
                                  rules: [(v) => !!v || "Campo obrigatório"],
                                  placeholder: "Título da push",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.templateToSave.pushTitle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.templateToSave,
                                      "pushTitle",
                                      $$v
                                    )
                                  },
                                  expression: "templateToSave.pushTitle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticStyle: { "margin-bottom": "15px" } },
                        [
                          _c("label", [
                            _vm._v(
                              "Para utilizar variáveis em seu template utilize da seguinte forma: $id"
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10", sm: "12" } },
                            [
                              _c(
                                "Mentionable",
                                {
                                  attrs: {
                                    keys: ["$"],
                                    items: _vm.variables,
                                    offset: "0",
                                    limit: 100,
                                    "insert-space": "",
                                    placement: "bottom",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "no-result",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "popoveruser",
                                              staticStyle: {
                                                "text-align": "center",
                                                padding: "15px",
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.choosenConnection
                                                      ? "Sem Resultados"
                                                      : "Selecione uma conexão!"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "item-$",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "popoveruser" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.value) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("z-input-area", {
                                    attrs: {
                                      max: "",
                                      rules: [
                                        (v) => !!v || "Campo obrigatório",
                                      ],
                                      hideDetails: true,
                                      placeholder: "Conteúdo",
                                      type: "textarea",
                                    },
                                    model: {
                                      value: _vm.templateToSave.message,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.templateToSave,
                                          "message",
                                          $$v
                                        )
                                      },
                                      expression: "templateToSave.message",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("z-input", {
                                attrs: {
                                  hideDetails: true,
                                  placeholder: "URL",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.templateToSave.redirectUrl,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.templateToSave,
                                      "redirectUrl",
                                      $$v
                                    )
                                  },
                                  expression: "templateToSave.redirectUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("z-btn", {
                                staticStyle: { width: "100% !important" },
                                attrs: {
                                  text: "Enviar imagem",
                                  primary: "",
                                  icon: "mdi-cloud-upload",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openFilePicker()
                                  },
                                },
                              }),
                              _c("input", {
                                ref: "file",
                                staticStyle: { display: "none" },
                                attrs: { type: "file", accept: ".png" },
                                on: { change: _vm.previewFiles },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.templateToSave.pushImageUrl
                        ? _c(
                            "v-row",
                            { staticStyle: { "margin-top": "10px" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "10" } },
                                [
                                  _c("z-btn", {
                                    staticStyle: {
                                      width: "100% !important",
                                      color: "white",
                                    },
                                    attrs: {
                                      text: "Remover imagem selecionada",
                                      primary: "",
                                      icon: "mdi-close",
                                      color: "red",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFilePicked()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "wrapper__card__body__right",
                  staticStyle: { height: "100% !important" },
                },
                [
                  _c("div", { staticClass: "iphone-x" }, [
                    _c("div", { staticClass: "side" }, [
                      _c("div", { staticClass: "screen" }, [
                        _c("div", { staticClass: "content" }, [
                          _vm._m(0),
                          _c("div", { staticClass: "superior_push_title" }, [
                            _c("span", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.titlePushToShow)),
                              ]),
                            ]),
                          ]),
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "box-message",
                                staticStyle: {
                                  "letter-spacing": "0.15px !important",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.transformString(
                                        _vm.templateToSave.message
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _vm.templateToSave.pushImageUrl
                            ? _c("div", { staticClass: "box-message__image" }, [
                                _c("img", {
                                  staticStyle: {
                                    "object-fit": "contain",
                                    width: "100%",
                                  },
                                  attrs: {
                                    src: _vm.templateToSave.pushImageUrl,
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "line" }),
                    _vm._m(1),
                    _c("div", { staticClass: "volume-button" }),
                    _c("div", { staticClass: "power-button" }),
                  ]),
                ]
              ),
            ]),
            _c("v-row", { attrs: { justify: "end" } }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showInformations,
            expression: "!showInformations",
          },
        ],
        staticClass: "wrapper no-data",
      },
      [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "superior_push" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-itens": "center",
          },
        },
        [
          _c("img", {
            staticStyle: {
              width: "20px !important",
              height: "20px !important",
              "margin-right": "5px",
            },
            attrs: { src: "/icons/monograma_zoox_2.svg", alt: "zoox" },
          }),
          _c(
            "span",
            { staticStyle: { "padding-top": "2px", "font-size": "0.8rem" } },
            [_vm._v("Zoox")]
          ),
        ]
      ),
      _c("div", [
        _c("span", { staticStyle: { "font-size": "0.8rem" } }, [
          _vm._v("Agora"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "sensor-2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }