import CreateTemplateModal from "../Common/CreateTemplateModal/index.vue";
import ApiClient from "@/services/ApiClient";
import "floating-vue/dist/style.css";
import { Mentionable } from "vue-mention";

export default {
  name: "CreatePushTemplate",
  components: {
    CreateTemplateModal,
    Mentionable,
  },
  data() {
    return {
      descSms: "",
      showInformations: true,
      showModal: false,
      loadingTempalte: false,
      typeTemplate: "",
      stepperValue: 1,
      dbConfigList: [],
      steppers: [
        { id: 1, label: "Conexão", icon: "$check_rounded" },
        { id: 2, label: "Mapeamento de Dados", icon: "$check_rounded" },
        { id: 3, label: "Informações auxiliares", icon: "$check_rounded" },
        { id: 4, label: "Atualização", icon: "$check_rounded" },
      ],
      step: 1,
      items: [],
      variables: [],
      mapping: [
        "ID Único",
        "Nome do usuário (opcional)",
        "Telefone",
        "ID SDK",
        "E-mail",
      ],
      mapping2: ["ID da compra", "Valor da compra", "Data da compra"],
      mapping3: ["Valor", "Produto"],
      text: "center",
      zStyle: {
        padding: "1.5rem .5rem",
        display: "flex",
        flexDirection: "column",
      },
      http: new ApiClient(),
      showTagsModal: false,
      choosenConnection: null,
      firstData: {},
      templateToSave: {
        message: "",
        pushTitle: "",
        templateType: "push",
        templateName: "",
        description: "",
        redirectUrl: "",
        pushImageUrl: null,
      },
      tags: [
        { text: "ID", value: "{ID}" },
        { text: "Nome", value: "{NAME}" },
        { text: "Telefone", value: "{PHONE}" },
        { text: "E-mail", value: "{EMAIL}" },
        { text: "SKU", value: "{SKUID}" },
      ],
      titlePushToShow: "",
    };
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.showInformations = false;
    }
  },
  async created() {
    await this.getPrimaryDataConfigList();
    if (this.$route.query.id) {
      this.showInformations = true;
      await this.http
        .get(`templatesConfiguration/findOne/${this.$route.query.id}`)
        .then(async (resp) => {
          this.templateToSave = resp;
          this.templateToSave.pushImageUrl =
            "https://data-to-act-front.s3.amazonaws.com/" +
            this.templateToSave.pushImageUrl;
          this.canShow = true;
          this.showInformations = true;
          if (this.templateToSave.databaseConfigId) {
            this.choosenConnection = this.dbConfigList.find(
              (x) => x.id == this.templateToSave.databaseConfigId
            );

            this.chooseCollection();
          }
        })
        .catch((err) => {
          throw err;
        });
    } else {
      this.canShow = true;
    }
  },
  watch: {
    "templateToSave.pushTitle": {
      handler(nv) {
        if (nv.length <= 32) {
          this.titlePushToShow = nv;
        } else {
          this.titlePushToShow = nv.substring(0, 29) + "...";
        }
      },
    },
  },
  computed: {
    titlePush() {},
  },
  methods: {
    async chooseCollection() {
      const keys = Object.keys(JSON.parse(this.choosenConnection.fields));
      // console.log(keys);

      this.variables = keys.map((key) => {
        return { value: key };
      });

      await this.http
        .get(`dbConfiguration/getFirstData/${this.choosenConnection.id}`)
        .then((resp) => {
          //    console.log(resp);
          this.firstData = resp;
        })
        .catch((err) => {
          throw err;
        });
    },
    transformString(text) {
      if (this.firstData && this.firstData.length > 0) {
        const keys = Object.keys(this.firstData[0]);
        for (let i = 0; i < keys.length; i++) {
          const rowWithData = this.firstData.find((x) => x[keys[i]]);

          // verificar se campo tem '.' e se tiver, verificar se é possivel converter o mesmo para um número
          let text_to_transform = "";
          let transformed = "";

          // inserção de bloco para caso o campo esteja invalido (null, undefined), não estoure um erro travando a digitação
          try {
            text_to_transform = `${rowWithData[keys[i]]}`;
          } catch (e) {
            text_to_transform = "";
          }

          if (text_to_transform.includes(".")) {
            const is_an_number = +rowWithData[keys[i]];
            if (is_an_number) {
              transformed = is_an_number.toLocaleString("pt-br");
            }
          }

          if (rowWithData)
            // text = text.replace(`$${keys[i]}`, rowWithData[keys[i]]);
            text = text.replace(
              `$${keys[i]}`,
              transformed ? transformed : rowWithData[keys[i]] || ""
            );
        }
      }
      return text;
    },
    onApply(item, key, replacedWith) {
      console.log(item, `has been replaced with ${replacedWith}`);
    },
    async getPrimaryDataConfigList() {
      try {
        const req = await this.http.get(
          "dbConfiguration/list?status=updated&sort=created&order=DESC"
        );
        this.dbConfigList = req;
      } catch (error) {
        this.$toast.error("Erro ao buscar lista de configurações");
      }
    },
    openModalTags() {
      this.showTagsModal = true;
    },
    async openFilePicker() {
      this.$refs.file.click();
    },

    removeFilePicked() {
      this.templateToSave.pushImageUrl = null;
    },

    async previewFiles(event) {
      const $context = this;
      this.selectedCSVFile = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async () => {
        $context.templateToSave.pushImageUrl = reader.result;
        $context.$toast.success("Upload da imagem feita com sucesso!");
      };
    },

    async saveTemplate() {
      const $context = this;
      if (this.$refs.form.validate()) {
        if (!this.$route.query.id) {
          this.loadingTempalte = true;

          this.templateToSave.databaseConfigId = this.choosenConnection?.id;

          try {
            const req = await $context.http.post(
              "templatesConfiguration/create",
              $context.templateToSave
            );
            if (req) {
              this.$toast.success("Template salvo com sucesso!");
              this.$router.push({ name: "templates" });
            }

            this.loadingTempalte = false;
          } catch (error) {
            this.$toast.error("Erro ao salvar template!");
            this.loadingTempalte = false;
          }
        } else {
          try {
            this.loadingTempalte = true;
            $context.templateToSave.newEmailTemplate = true;
            const req = await $context.http.put(
              `templatesConfiguration/${this.$route.query.id}`,
              $context.templateToSave
            );

            if (req) this.$toast.success("Template atualizado com sucesso");
            this.loadingTempalte = false;
          } catch (error) {
            this.$toast.error("Erro ao atualizar template!");
            this.loadingTempalte = false;
          }
        }
      } else {
        this.$toast.error("Preencha os campos obrigatórios!");
      }
    },
    showModalTemplate(typeTemplate) {
      this.showModal = true;
      this.typeTemplate = typeTemplate;
    },
  },
};
